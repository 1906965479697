class SvgIcons {
  constructor() {
    this.icons = document.querySelectorAll('.svg-anim-icon')
    this.init()
  }

  setUpAnimLines() {
    this.icons.forEach(icon => {
      const svgLines = icon.querySelectorAll('.anim-line')
      const svgFills = icon.querySelectorAll('.anim-fill')
      svgLines.forEach(line => {
        // line.dataset.length = line.getTotalLength();
        // line.style.strokeDasharray = line.dataset.length;
        // line.style.strokeDashoffset = line.dataset.length;
        // line.style.transitionDelay = `${Math.random() * 0.5}s`
      })
      svgFills.forEach(fill => {
        // fill.style.opacity = 0
      })
    })
  }

  init() {
    // this.setUpAnimLines()
    this.icons.forEach(icon => {
      new SvgIcon(icon)
    })
  }
}

class SvgIcon {
  constructor(icon) {
    this.icon = icon
    this.lines = this.icon.querySelectorAll('.anim-line')
    this.fills = this.icon.querySelectorAll('.anim-fill')
    this.observeIntersections = this.observeIntersections.bind(this)
    this.init()
  }

  observeIntersections() {
    let headerHeight = document.querySelector('.header').offsetHeight
    let options = {
      rootMargin: `-${headerHeight}px 0px 0px 0px`,
      threshold: 1
    }
    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.lines.forEach(line => {
            line.style.strokeDashoffset = 0
            line.style.opacity = 1
          })
          this.fills.forEach(fill => {
            fill.style.opacity = 1
          })
        }
        // on out of view, back to iintital
        // else {
        //   this.lines.forEach(line => {
        //     line.style.strokeDashoffset = line.dataset.length
        //     line.style.opacity = 0
        //   })
        //   this.fills.forEach(fill => {
        //     fill.style.opacity = 0
        //   })
        // }
      })
    }

    if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver(callback, options)
      observer.observe(this.icon)
    }
  }

  init() {
    this.observeIntersections()
  }
}

export default SvgIcons
