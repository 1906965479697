class SvgBottle {
  constructor(bottle) {
    this.svgBottle = bottle;
    this.svgIconWrap = this.svgBottle.querySelector('.svg-bottle-stripe-wrap')
    this.svgIconStripe = this.svgBottle.querySelector('.svg-bottle-stripe')
    this.liquidWrap = this.svgBottle.querySelector('.svg-bottle_liquid-wrap')
    this.liquidBottle = this.svgBottle.querySelector('.svg-bottle_bottle')
    this.liquid1 = this.svgBottle.querySelector('.svg-bottle_liquid-1')
    this.liquid2 = this.svgBottle.querySelector('.svg-bottle_liquid-2')
    this.svgIconStripemaxY = +this.svgIconWrap.getAttribute('height')
    this.liquidMaxY = this.liquidBottle.getBoundingClientRect().height * 0.5
    this.svgStripeInc = -this.svgIconStripe.getAttribute('width')
    this.liquidRotation = 0
    this.liquidInc = 0
    this.up = true
    this.init();
  }

  map(value, minA, maxA, minB, maxB) {
    return (
      (1 - (value - minA) / (maxA - minA)) * minB +
      ((value - minA) / (maxA - minA)) * maxB
    )
  }

  liquidFill(scroll, oldScroll) {
    if (this.up == true && this.liquidInc <= this.liquidMaxY) {
      this.liquidInc += this.map(scroll - oldScroll, 1, 100, 1, 10)

      if (this.liquidInc >= this.liquidMaxY) {
        this.up = false
      }
    } else {
      this.up = false
      this.liquidInc -= this.map(scroll - oldScroll, 1, 100, 7, 70)
      if (this.liquidInc <= 0) {
        this.up = true
      }
    }
    this.liquidWrap.style.transform = `translate3D(0, -${this.liquidInc}px, 0)`
  }

  init() {
    let that = this;
    window.onscroll = function(e) {
      if (this.oldScroll < this.scrollY) {
        that.svgStripeInc += that.map(this.scrollY - this.oldScroll, 1, 100, 10, 20)
        that.liquidRotation += that.map(this.scrollY - this.oldScroll, 1, 100, 0.5, 1)

        if (that.svgStripeInc > that.svgIconStripemaxY) {
          that.svgStripeInc = -that.svgIconStripe.getAttribute('width')
        } else {
          that.svgIconStripe.style.transform = `translate3D(0, ${that.svgStripeInc}px, 0)`
        }

        if (that.liquidRotation > 360) {
          that.liquidRotation = 0
        } else {
          that.liquid1.style.transform = `rotate(${that.liquidRotation}deg)`
          that.liquid2.style.transform = `rotate(-${that.liquidRotation}deg)`
        }

        that.liquidFill(this.scrollY, this.oldScroll)
      }
      this.oldScroll = this.scrollY
    }
  }
}


export default SvgBottle