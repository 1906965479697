import { wr_siblings } from '../../../helpers/helpers'

class Accordions {
  constructor(selector) {
    if (document.querySelectorAll(selector).length) {
      this.selector = selector
      this.accordions = document.querySelectorAll(selector)
      this.toggleItem = this.toggleItem.bind(this)
      this.bodySelector = '.accordion-item__body'
      this.toggleSelector = '.accordion-item__btn'
      this.init()
    }
  }

  toggleItem(e) {
    e.preventDefault()
    const accItem = e.target.parentElement
    const body = e.target.nextElementSibling
    const siblings = wr_siblings(accItem)
    siblings.forEach(sibling => {
      sibling.classList.remove('active')
      sibling.querySelector(this.bodySelector).style.maxHeight = null
    })
    if (body.style.maxHeight) {
      body.style.maxHeight = null
      accItem.classList.remove('active')
    } else {
      body.style.maxHeight = body.scrollHeight + 'px'
      accItem.classList.add('active')
    }
  }

  init() {
    this.accordions.forEach((accordion, i) => {
      const items = accordion.querySelectorAll(this.toggleSelector)
      items.forEach((item, j) => {
        item.addEventListener('click', this.toggleItem)
      })
    })
  }
}

export default Accordions
