class NumbersCount {
  constructor() {
    this.allNumbersSections = document.querySelectorAll('.js-count-wrap')
    this.duration = 2500
    this.finished = false
    this.init()
  }

  numFormatter(num, formatingBool) {
    if (formatingBool) {
      if (Math.abs(num) > 999 && Math.abs(num) < 999999) {
        return Math.sign(num) * (Math.abs(num) / 999).toFixed(1) + 'K'
      } else if (Math.abs(num) > 999999) {
        return Math.sign(num) * (Math.abs(num) / 999999).toFixed(1) + 'M'
      } else {
        return Math.sign(num) * Math.abs(num)
      }
    } else {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  animateValue(parent, el, start, end, duration) {
    let formatingBool = parent.classList.contains('format-number')
      ? true
      : false
    let range = end - start
    let minTimer = 50
    let stepTime = Math.abs(Math.floor(duration / range))
    stepTime = Math.max(stepTime, minTimer)
    let startTime = new Date().getTime()
    let endTime = startTime + duration
    let timer
    const run = () => {
      let now = new Date().getTime()
      let remaining = Math.max((endTime - now) / duration, 0)
      let value = Math.round(end - remaining * range)
      el.innerHTML = this.numFormatter(value, formatingBool)
      if (value == end) {
        clearInterval(timer)
      }
    }

    timer = setInterval(run, stepTime)
    run()
  }

  loopNumbers(parent) {
    let number = parent.querySelector('.js-count-number')
    number.dataset.countMax = number.dataset.countMax.replace(/\D/g, '')
    let numToLowerC = number.dataset.countMax.toLowerCase().replace(/\D/g, '')
    if (parent.dataset.countDone === 'false') {
      if (numToLowerC.includes('k')) {
        this.animateValue(
          number,
          0,
          numToLowerC.split('k')[0] * 1000,
          this.duration
        )
      } else if (numToLowerC.includes('m')) {
        this.animateValue(
          number,
          0,
          numToLowerC.split('m')[0] * 1000000,
          this.duration
        )
      } else {
        this.animateValue(
          parent,
          number,
          0,
          number.dataset.countMax,
          this.duration
        )
      }
    }
  }

  observeIntersections() {
    let options = {
      rootMargin: `${
        document.querySelector('.header').offsetHeight
      }px 0px 0px 0px`,
      threshold: 1
    }
    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.dataset.countDone === 'false') {
            this.loopNumbers(entry.target)
          }
          entry.target.dataset.countDone = true
        } else {
          // uncomment code below to have animation in both ways
          // entry.target.dataset.countDone = false
        }
      })
    }
    if (this.allNumbersSections) {
      const observables = Array.from(this.allNumbersSections)
      if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver(callback, options)
        observables.forEach(observable => {
          if (observable.dataset.countDone === 'false') {
            observer.observe(observable)
          }
        })
      }
    } else {
      return false
    }
  }

  init() {
    if (this.allNumbersSections.length) {
      this.observeIntersections()
    }
  }
}

export default NumbersCount
