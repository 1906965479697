class ParallaxController {
  constructor(allParallaxesArr) {
    this.allParallaxes = allParallaxesArr
    this.allScenes = []
    this.init()
  }

  events() {
    document.addEventListener('scroll', () => {
      this.allScenes.forEach(scene => {
        scene.moveScene()
      })
    })
    window.addEventListener('resize', () => {
      this.allScenes.forEach(scene => {
        scene.onResize()
        scene.moveScene()
      })
    })
  }

  initScenes() {
    this.allScenes.forEach(scene => {
      scene.moveScene()
    })
  }

  init() {
    this.allParallaxes.forEach(parallax => {
      const scenes = parallax.querySelectorAll('.scene')
      scenes.forEach(scene => {
        const parallaxScene = new ParallaxScene(parallax, scene)
        this.allScenes.push(parallaxScene)
      })
    })

    this.initScenes()
    this.events()
  }
}

class ParallaxScene {
  constructor(parent, scene) {
    this.parent = parent
    this.scene = scene
    this.windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.distance = this.scene.dataset.distance
    this.dataOffset = this.parent.dataset.offset
    this.start =
      this.offset(this.parent).top - this.windowHeight - this.dataOffset
    this.end = this.offset(this.parent).top + this.windowHeight
  }

  offset(el) {
    let rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  onResize() {
    this.windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.start =
      this.offset(this.parent).top - this.windowHeight - this.dataOffset
    this.end = this.offset(this.parent).top + this.windowHeight
  }

  moveScene() {
    this.scene.style.transform = `translate3D(0, -${this.map(
      window.scrollY,
      this.start,
      this.end,
      0,
      this.distance
    )}px, 0)`
  }

  map(value, minA, maxA, minB, maxB) {
    let val =
      (1 - (value - minA) / (maxA - minA)) * minB +
      ((value - minA) / (maxA - minA)) * maxB
    if (val > maxB) {
      return maxB
    } else if (val < minB) {
      return minB
    } else {
      return val
    }
  }
}

export default ParallaxController
