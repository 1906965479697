class IntersectionTransitions {
  constructor() {
    this.observeIntersections = this.observeIntersections.bind(this)
    this.init()
  }

  observeIntersections() {
    const allTargets = document.querySelectorAll('.ivtr') || false
    let options = {
      rootMargin: '60px 0px 0px 0px',
      threshold: 0.5
    }
    let callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('inView')
        }
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        //   entry.isIntersecting
        //   entry.rootBounds
        //   entry.target
        //   entry.time
      })
    }
    if (allTargets) {
      const observables = Array.from(allTargets)
      if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver(callback, options)
        observables.forEach(observable => {
          observer.observe(observable)
        })
      }
    } else {
      return false
    }
  }

  init() {
    this.observeIntersections()
  }
}

export default IntersectionTransitions
