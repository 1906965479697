import EmblaCarousel from 'embla-carousel'

const emblaDefaultOptions = {
  align: 'start',
  inViewThreshold: 0.9,
  containerSelector: '*',
  speed: 10,
  startIndex: 0,
  selectedClass: 'is-selected',
  draggableClass: 'is-draggable',
  draggingClass: 'is-dragging'
}

class EmblaSliders {
  /**
   * @param {HTMLelement} wrap // embla wrap
   * @param {[]} breakpoints // [query, slidesToScroll, flex, Dots Display, Arrows Display, draggable, container flex, slides gap]
   */

  constructor(wrap, breakpoints) {
    this.wrap = wrap
    this.options = { ...emblaDefaultOptions }
    this.emblaNode = this.wrap.querySelector('.embla')
    this.emblaSlides = this.emblaNode.querySelectorAll('.embla__slide')
    this.dotsbtnsWrap = this.wrap.closest('[data-slider-wrap]') || this.wrap
    this.emblaDots = this.dotsbtnsWrap.querySelector('.embla__dots')
    this.emblaContainer = this.emblaNode.querySelector('.embla__container')
    this.prevBtn = this.dotsbtnsWrap.querySelector('.embla__btn-prev') || false
    this.nextBtn = this.dotsbtnsWrap.querySelector('.embla__btn-next') || false
    this.breakpoints = breakpoints
    this.embla
    this.dotsArray
    this.setSelectedDotBtn
    this.progressBar
    this.progressBarInner
    this.timer = null
    this.timerAnim = null
    this.isRunning = false
    this.classInit()
  }

  map(value, minA, maxA, minB, maxB) {
    let val =
      (1 - (value - minA) / (maxA - minA)) * minB +
      ((value - minA) / (maxA - minA)) * maxB

    if (val < minB) return minB
    if (val > maxB) return maxB
    return val
  }

  checkArrow() {
    this.embla.canScrollNext()
      ? this?.nextBtn && this.nextBtn.classList.remove('btn-disabled')
      : this?.nextBtn && this.nextBtn.classList.add('btn-disabled')
    this.embla.canScrollPrev()
      ? this?.prevBtn && this.prevBtn.classList.remove('btn-disabled')
      : this?.prevBtn && this.prevBtn.classList.add('btn-disabled')
  }

  sliderArrows() {
    this.checkArrow()

    this.embla.on('select', () => {
      this.checkArrow()
      if (this.emblaNode.classList.contains('slide-nav')) {
        this.slideNav()
      }
    })
    this?.prevBtn &&
      this.prevBtn.addEventListener(
        'click',
        () => {
          this.embla.scrollPrev()
          this.checkArrow()
        },
        true
      )

    this?.nextBtn &&
      this.nextBtn.addEventListener(
        'click',
        () => {
          this.embla.scrollNext()
          this.checkArrow()
        },
        true
      )
  }

  slidesPerView() {
    for (let i = 0; i < this.emblaSlides.length; i++) {
      if (i == this.embla.selectedScrollSnap()) {
        this.emblaSlides[i].classList.add('embla-js-active')
      } else {
        this.emblaSlides[i].classList.remove('embla-js-active')
      }
    }
  }

  emblaDotsSetUp() {
    const removeAllChildNodes = parent => {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
      }
    }

    const selectDotBtn = (dotsArray, embla) => {
      const previous = embla.previousScrollSnap()
      const selected = embla.selectedScrollSnap()
      dotsArray[previous].classList.remove(emblaDefaultOptions.selectedClass)
      dotsArray[selected].classList.add(emblaDefaultOptions.selectedClass)
    }

    const sliderDots = () => {
      removeAllChildNodes(this.emblaDots)
      this.dotsArray = generateDotBtns(this.emblaDots, this.embla)
      selectDotBtn(this.dotsArray, this.embla)
      setupDotBtns(this.dotsArray, this.embla)
      this.embla.on('select', () => {
        selectDotBtn(this.dotsArray, this.embla)
      })
      this.embla.on('init', () => {
        selectDotBtn(this.dotsArray, this.embla)
      })
    }

    const setupDotBtns = (dotsArray, embla) => {
      dotsArray.forEach((dotNode, i) => {
        if (this.emblaNode.classList.contains('js-embla--image-dots')) {
          const dotImages = JSON.parse(this.emblaNode.dataset.dotsImages)
          dotNode.style.backgroundImage = `url('${dotImages[i]}')`
          dotNode.style.zIndex = dotsArray.length - i
        }
        dotNode.classList.add('embla__dot')
        dotNode.addEventListener(
          'click',
          () => {
            embla.scrollTo(i)
          },
          false
        )
      })
    }

    const generateDotBtns = (dots, embla) => {
      const scrollSnaps = embla.scrollSnapList()
      const dotsFrag = document.createDocumentFragment()
      const dotsArray = scrollSnaps.map(() => document.createElement('button'))
      dotsArray.forEach((dotNode, i) => {
        dotNode.innerHTML = `0${i + 1}`
        dotsFrag.appendChild(dotNode)
      })
      dots.appendChild(dotsFrag)
      this.wrap.style.setProperty('--dots-length', dotsArray.length)
      return dotsArray
    }

    return { sliderDots }
  }

  loopBP(index, prop) {
    const defaultOptions = {
      slidesToScroll: 1,
      slideWidth: '50%',
      dotsDisplay: 'flex',
      arrowsDisplay: 'flex',
      draggable: true,
      wrapSlides: false,
      marginBottom: 0,
      gap: 0,
      loop: false,
      containScroll: false,
      dragFree: false,
      autoplay: false,
      slideDuration: 2000,
      align: 'start'
    }

    const options = this.breakpoints[index].options

    if (index === 0) {
      for (const [key, value] of Object.entries(
        options.hasOwnProperty(prop) ? options : defaultOptions
      )) {
        if (key === prop) return value
      }
    } else {
      if (options.hasOwnProperty(prop)) {
        for (const [key, value] of Object.entries(options)) {
          if (key === prop) return value
        }
      } else {
        return this.loopBP(index - 1, prop)
      }
    }
  }

  sliderDef() {
    this.breakpoints.forEach((breakpoint, key) => {
      if (window.matchMedia(breakpoint.query).matches) {
        this.emblaSlides.forEach(slide => {
          slide.style.flex = `0 0 ${this.loopBP(key, 'slideWidth')}`
          slide.style.maxWidth = `${this.loopBP(key, 'slideWidth')}`
          slide.style.marginBottom = `${this.loopBP(key, 'marginBottom')}px`
          slide.querySelector(
            '.embla__slide__inner'
          ).style.padding = `0 ${this.loopBP(key, 'gap') * 0.5}px`
        })

        this.emblaContainer.style.flexWrap = this.loopBP(key, 'wrapSlides')
          ? 'wrap'
          : 'nowrap'
        this.emblaContainer.style.width = `calc(100% + ${this.loopBP(
          key,
          'gap'
        )}px)`
        this.emblaContainer.style.marginLeft = `-${this.loopBP(key, 'gap') *
          0.5}px`
        this.emblaContainer.style.marginBottom = `-${this.loopBP(
          key,
          'marginBottom'
        )}px`

        // if (this.loopBP(key, 'autoplay')) {
        //   this.emblaNode.classList.add('autoplay')
        //   this.emblaNode.classList.add('autoplay-play')
        //   this.emblaNode.classList.remove('autoplay-stop')
        //   this.emblaNode.dataset.slideDuration = this.loopBP(
        //     key,
        //     'slideDuration'
        //   )
        //   this.autoplay().play
        // } else {
        //   this.emblaNode.classList.add('autoplay-stop')
        //   this.emblaNode.classList.remove('autoplay-play')
        //   this.autoplay().stop
        //   this.autoplay().resetProgress
        // }

        this.emblaDots.style.display =
          this.emblaSlides.length === 1
            ? 'none'
            : this.loopBP(key, 'dotsDisplay')
            ? 'flex'
            : 'none'
        if (this?.prevBtn) {
          this.prevBtn.style.display =
            this.emblaSlides.length === 1
              ? 'none'
              : this.loopBP(key, 'arrowsDisplay')
              ? 'flex'
              : 'none'
        }
        if (this?.nextBtn) {
          this.nextBtn.style.display =
            this.emblaSlides.length === 1
              ? 'none'
              : this.loopBP(key, 'arrowsDisplay')
              ? 'flex'
              : 'none'
        }

        if (this.emblaSlides.length === 1) {
          this.emblaNode.classList.contains('autoplay') &&
            this.emblaNode.classList.remove('autoplay')
          this.wrap.classList.add('static')
        }

        if (this.loopBP(key, 'draggable') === false) {
          this.emblaContainer.classList.add('no-transform')
        } else {
          this.emblaContainer.classList.remove('no-transform')
        }

        if (
          this.embla.slideNodes().length ===
          100 / Number.parseFloat(this.loopBP(key, 'slideWidth'))
        ) {
          this.emblaContainer.classList.add('no-transform')
          this.dotsbtnsWrap.querySelector('.embla__buttons').style.display =
            'none'

          if (this.emblaNode.classList.contains('indicators')) {
            this.dotsbtnsWrap.querySelector(
              '.embla__indicators'
            ).style.display = 'none'
          }
        } else {
          this.dotsbtnsWrap.querySelector('.embla__buttons').style.display = ''

          if (this.emblaNode.classList.contains('indicators')) {
            this.dotsbtnsWrap.querySelector(
              '.embla__indicators'
            ).style.display = ''
          }
        }

        this.embla.reInit({
          align: this.loopBP(key, 'align'),
          slidesToScroll: this.loopBP(key, 'slidesToScroll'),
          draggable:
            this.emblaSlides.length === 1 ||
            this.embla.slideNodes().length ===
              this.loopBP(key, 'slidesToScroll')
              ? false
              : this.loopBP(key, 'draggable'),
          loop:
            this.emblaSlides.length === 1 ? false : this.loopBP(key, 'loop'),
          containScroll: this.loopBP(key, 'containScroll'),
          dragFree: this.loopBP(key, 'dragFree')
        })
      }
    })
  }

  onResize() {
    this.sliderDef()
    if (this.emblaNode.classList.contains('slide-nav')) {
      this.slideNav()
    }
    if (this.emblaNode.classList.contains('indicators')) {
      this.setIndicators(
        this.emblaSlides.length,
        this.embla.internalEngine().options.slidesToScroll,
        this.embla.internalEngine().index.get() + 1
      )
    }
    if (
      this.embla.scrollSnapList().length !== this.emblaDots.childElementCount
    ) {
      this.emblaDotsSetUp().sliderDots()
    }
    this.checkArrow()
  }

  sliderSetUp() {
    this.embla = EmblaCarousel(this.emblaNode, this.options)
    this.sliderDef()
    this.emblaDotsSetUp().sliderDots()
  }

  numberWithinProgress(number) {
    return Math.min(Math.max(number, 0), 1)
  }

  createScrollProgressUtils(embla) {
    const withinBounds = distance => {
      const { target, limit } = embla.internalEngine()
      const { min, max, reachedMin, reachedMax } = limit
      const destination = target.get() + distance

      if (reachedMax(destination)) return max - target.get()
      if (reachedMin(destination)) return min - target.get()
      return distance
    }

    const currentProgress = () => {
      const { target, scrollProgress } = embla.internalEngine()
      return scrollProgress.get(target.get())
    }

    const add = progress => {
      const { limit, options } = embla.internalEngine()
      if (options.loop) return limit.length * progress
      return withinBounds(limit.length * progress * -1)
    }

    const set = progress => {
      const { limit } = embla.internalEngine()
      const allowedProgress = this.numberWithinProgress(progress)
      const progressToTarget = allowedProgress - currentProgress()
      return progressToTarget * limit.length * -1
    }

    return {
      scrollToProgress: (progress, snap) => {
        const { scrollBody, scrollTo } = embla.internalEngine()
        const distance = set(progress)
        scrollBody.useBaseMass().useBaseSpeed()
        scrollTo.distance(distance, !!snap)
      },
      scrollByProgress: (progress, snap) => {
        const { scrollBody, scrollTo } = embla.internalEngine()
        const distance = add(progress)
        scrollBody.useBaseMass().useBaseSpeed()
        scrollTo.distance(distance, !!snap)
      }
    }
  }

  slideNav() {
    const slideNav = this.emblaNode.parentElement.querySelector(
      '.slide-nav-button'
    )
    const dots = [...this.emblaDots.querySelectorAll('.embla__dot')]
    dots.forEach(dot => {
      if (dot.classList.contains('is-selected')) {
        slideNav.style.left = `${dot.offsetLeft}px`
        setTimeout(function() {
          slideNav.style.left = `${dot.offsetLeft}px`
        }, 500)
      }
    })
    slideNav.style.opacity = 1
  }

  autoplay() {
    let slideDuration = this.emblaNode.dataset.slideDuration
      ? this.emblaNode.dataset.slideDuration
      : 1000

    const createProgressBar = () => {
      this.progressBar = document.createElement('div')
      this.progressBarInner = document.createElement('div')
      this.progressBar.classList.add('progressBar')
      this.progressBarInner.classList.add('progressBar__inner')
      this.wrap.appendChild(this.progressBar)
      this.progressBar.appendChild(this.progressBarInner)
    }

    const play = () => {
      if (!this.isRunning) {
        this.isRunning = true
        stop()
        resetProgress()
        this.timer = setTimeout(next, +slideDuration)
        this.timerAnim = setTimeout(animateNext, 1)
      }
    }

    const stop = () => {
      if (this.isRunning) {
        clearTimeout(this.timer)
        this.isRunning = false
      }
    }

    const animateNext = () => {
      this.progressBarInner.style.transition = `all ${+slideDuration /
        1000}s linear`
      if (this.progressBar.dataset.reverse) {
        this.progressBar.style.setProperty('--progress', 0)
      } else {
        this.progressBar.style.setProperty('--progress', 1)
      }
    }

    const resetProgress = () => {
      clearTimeout(this.timerAnim)
      this.timerAnim = null
      this.progressBarInner.style.transition = 'none'
      if (this.progressBar.dataset.reverse) {
        this.progressBar.style.setProperty('--progress', 1)
      } else {
        this.progressBar.style.setProperty('--progress', 0)
      }
    }

    const next = () => {
      if (this.embla.canScrollNext()) {
        this.embla.scrollNext()
      } else {
        this.embla.scrollTo(0)
      }
    }

    return { play, stop, createProgressBar, resetProgress }
  }

  emblaAutoPlay() {
    this.autoplay().createProgressBar()
    this.embla.on('init', this.autoplay().play)

    //so that progress is reset to 0
    this.embla.on('select', this.autoplay().play)
  }

  setIndicators(max, current, newCurr) {
    const indicatorCurrent = this.wrap.querySelector('.ind-current')
    const indicatorOf = this.wrap.querySelector('.ind-of')
    let indOfNo = Math.ceil(max / current)
    let indCurr = Math.ceil(newCurr)
    if (indOfNo < 10) {
      indOfNo = `0${indOfNo}`
    }
    indicatorOf.textContent = indOfNo

    if (indCurr < 10) {
      indCurr = `0${indCurr}`
    }
    indicatorCurrent.textContent = indCurr
  }

  emblaIndicators() {
    this.embla.on('select', () => {
      this.setIndicators(
        this.emblaSlides.length,
        this.embla.internalEngine().options.slidesToScroll,
        this.embla.internalEngine().index.get() + 1
      )
    })
  }

  classInit() {
    this.sliderSetUp()
    this.emblaNode.style.opacity = 1

    if (this.emblaNode.classList.contains('embla-js-slides-per-view')) {
      this.slidesPerView()
      this.embla.on('select', () => {
        this.slidesPerView()
      })
    }

    this.sliderArrows()

    this.emblaNode.classList.contains('slide-nav') && this.slideNav()

    this.emblaNode.classList.contains('autoplay') && this.emblaAutoPlay()

    this.emblaNode.classList.contains('indicators') &&
      (() => {
        this.setIndicators(
          this.emblaSlides.length,
          this.embla.internalEngine().options.slidesToScroll,
          this.embla.internalEngine().index.get() + 1
        )
        this.emblaIndicators()
      })()

    window.addEventListener('resize', () => {
      this.onResize()
    })
  }
}

export default EmblaSliders
