import { wr_scrollTop } from '../../../helpers/helpers'

class HeroJs {
  constructor() {
    this.hero = document.querySelector('.hero-home')
    this.scrollTop
    this.init()
  }

  checkHeroPos() {
    this.scrollTop = wr_scrollTop()
    let heroHeight = this.hero.offsetHeight
    document.querySelector(
      '.main-content__inner'
    ).style.marginTop = `${heroHeight}px`
    if (this.scrollTop > heroHeight) {
      this.hero.style.opacity = 0
      this.hero.style.pointerEvents = 'none'
    } else {
      this.hero.style.opacity = 1
      this.hero.style.pointerEvents = 'all'
    }
  }

  map(value, minA, maxA, minB, maxB) {
    let val =
      (1 - (value - minA) / (maxA - minA)) * minB +
      ((value - minA) / (maxA - minA)) * maxB
    if (val > maxB) {
      return maxB
    } else if (val < minB) {
      return minB
    } else {
      return val
    }
  }

  init() {
    this.checkHeroPos()
    document.querySelector(
      '.main-content__inner'
    ).style.marginTop = `${this.hero.offsetHeight}px`

    let that = this
    document.addEventListener('scroll', function(e) {
      that.hero.querySelector(
        '.hero-home__image'
      ).style.transform = `translate3D(0, -${that.map(
        window.scrollY,
        0,
        that.hero.offsetHeight,
        0,
        200
      )}px, 0)`
      that.checkHeroPos()
    })

    window.addEventListener('resize', e => {
      that.checkHeroPos()
    })
  }
}

export default HeroJs;