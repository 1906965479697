class Tabs {
  constructor(selector) {
    if (document.querySelectorAll(selector).length) {
      this.allTabs = document.querySelectorAll(selector)
      this.init()
    }
  }

  removeAllActiveTabs(activeTabs) {
    for (let i = 0; i < activeTabs.length; i++) {
      const prevActiveTab = activeTabs[i]
      prevActiveTab.classList.remove('activeTab')
    }
  }

  slideNav(anchor, parent) {
    let slideNav = parent.querySelector('.slide-nav-button')
    if (anchor.classList.contains('activeTab')) {
      slideNav.style.width = `${anchor.offsetWidth}px`
      slideNav.style.left = `${anchor.offsetLeft}px`
      setTimeout(function() {
        slideNav.style.width = `${anchor.offsetWidth}px`
        slideNav.style.left = `${anchor.offsetLeft}px`
      }, 500)
    }
  }

  onTabClick(anchor, parent) {
    let activeTabs = parent.querySelectorAll('.activeTab')
    // remove previous active classes
    this.removeAllActiveTabs(activeTabs)

    anchor.classList.add('activeTab')
    const panelID = anchor.dataset.href
    const panel = parent.querySelector(`[data-id="${panelID}"]`)

    if (parent.classList.contains('slide-nav')) {
      this.slideNav(anchor, parent)
    }

    panel.classList.add('activeTab')
  }

  init() {
    this.allTabs.forEach(tabs => {
      const allAnchors = [...tabs.querySelectorAll('.tab-anchor')]
      allAnchors.forEach(anchor => {
        if (anchor.closest('.slide-nav')) {
          this.slideNav(anchor, tabs)
        }
        anchor.addEventListener('click', e => {
          e.preventDefault()
          this.onTabClick(anchor, tabs)
        })
      })
    })

    window.addEventListener('resize', e => {
      this.allTabs.forEach(tabs => {
        const allAnchors = [...tabs.querySelectorAll('.tab-anchor')]
        allAnchors.forEach(anchor => {
          if (anchor.closest('.slide-nav')) {
            this.slideNav(anchor, tabs)
          }
        })
      })
    })
  }
}

export default Tabs
