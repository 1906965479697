class Dropdowns {
  constructor() {
    this.listItems = document.querySelectorAll('.dropdown')
    this.anchors = document.querySelectorAll('.dropdown-toggle')
    this.menus = document.querySelectorAll('.dropdown-menu')
    this.closeBtns = document.querySelectorAll('.close-dropdown')
    this.init()
  }

  anchorsLoop() {
    this.listItems.forEach((listItem, i) => {
      const menu = this.menus[i]
      const anchor = this.anchors[i]
      const close = this.closeBtns[i]

      document.addEventListener('click', e => {
        if (e.target !== anchor && e.target !== menu) {
          if (listItem.classList.contains('show')) {
            listItem.classList.remove('show')
            menu.classList.remove('show')
            anchor.classList.remove('active')
          }
        }
      });

      anchor.addEventListener('click', e => {
        e.preventDefault()

        if (listItem.classList.contains('show')) {
          listItem.classList.remove('show')
          menu.classList.remove('show')
          anchor.classList.remove('active')
        } else {
          listItem.classList.add('show')
          menu.classList.add('show')
          anchor.classList.add('active')
        }
      });

      close.addEventListener('click', e => {
        listItem.classList.remove('show')
        menu.classList.remove('show')
      })
    })
  }

  init() {
    this.anchorsLoop()
  }
}

export default Dropdowns;
