class VideoModal {
  constructor() {
    this.$videoModalTrigger = document.querySelector('.video-modal-trigger')
    if (this.$videoModalTrigger) {
      this.videoURL = this.$videoModalTrigger.dataset.videoId
      this.videoType = this.$videoModalTrigger.dataset.videoType

      this.$backdrop = document.querySelector('.backdrop')
      this.$mainModal = document.querySelector('#main-modal')
      this.$mainModalCloseTrigger = this.$mainModal.querySelector(
        '.modal--custom-close-x'
      )
      this.$modalContentWrap = this.$mainModal.querySelector(
        '.modal--custom__content--wrap'
      )
      this.modalTransitionTimeMs = +getComputedStyle(
        document.documentElement
      ).getPropertyValue('--modal-transition')

      this.init()
    }
    // Get the position of the element with class ".btn-container-hero"

  }

  getFormattedTmHTML() {
    let modalVideoContent
    if (this.videoType === 'youtube') {
      modalVideoContent = `
        <div class="video-wrapper-width">
          <div class="video-wrapper">
            <iframe 
              src="https://www.youtube.com/embed/${this.videoURL}?autoplay=1" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen
            ></iframe>
          </div>
        </div>
      `
    } else if (this.videoType === 'vimeo') {
      modalVideoContent = `
        <div class="video-wrapper-width">
          <div class="video-wrapper">
            <iframe 
              src="https://player.vimeo.com/video/${this.videoURL}?portrait=0&autoplay=1" 
              style="position:absolute;top:0;left:0;width:100%;height:100%;" 
              frameborder="0" 
              allow="autoplay; 
              fullscreen; 
              picture-in-picture" 
              allowfullscreen></iframe>
            </div>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
      `
    } else if (this.videoType === 'selfhosted') {
      modalVideoContent = `
        <div class="video-wrapper-width">
          <div class="video-wrapper">
            <iframe 
              src="${this.videoURL}?autoplay=1" 
              frameborder="0" 
              allow="autoplay;" 
              allowfullscreen
            ></iframe>
          </div>
        </div>
      `
    }

    return modalVideoContent
  }

  openModalAndStartVideo() {
    if (document.body.classList.contains('js-menu-open')) {
      document.body.classList.remove('js-menu-open')
      document.querySelector('.navbar-toggler').classList.remove('toggle-on')
      document.getElementById('wrwps-main-menu').classList.remove('navMenu-on')
    }
    this.$modalContentWrap.innerHTML = this.getFormattedTmHTML()
    document.body.classList.add('js-backdropOn')
    this.$mainModal.classList.add('modal--video')
    this.$mainModal.classList.add('modal-is-open')
  }

  closeModal() {
    document.body.classList.remove('js-backdropOn')
    this.$backdrop.classList.add('js-loader-hidden')
    this.$mainModal.classList.remove('modal-is-open')
    setTimeout(() => {
      this.$mainModal.classList.remove('modal--video')
      this.$modalContentWrap.innerHTML = ''
    }, this.modalTransitionTimeMs)
  }

  attachEvents() {
    this.$mainModalCloseTrigger.addEventListener('click', () => {
      this.closeModal()
    })
    this.$backdrop.addEventListener('click', () => {
      this.closeModal()
    })
    this.$videoModalTrigger.addEventListener('click', () => {
      this.openModalAndStartVideo()
    })
  }

  init() {
    this.attachEvents()
  }
}

export { VideoModal }
