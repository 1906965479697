import EmblaSliders from './UI/EmblaSliders'

export default function slidersInit() {
  if (document.querySelector('.embla__latest-posts')) {
    const wrap = document.querySelector('.embla__latest-posts').parentElement
    const options = {
      slidesToScroll: 3,
      containScroll: 'trimSnaps',
      draggable: true,
      arrowsDisplay: false,
      dotsDisplay: true,
      slideWidth: `${100 / 3}%`,
      wrapSlides: false,
      gap: 40,
      align: 'start'
    }
    new EmblaSliders(wrap, [
      {
        query: '(min-width: 1200px)',
        options: { ...options }
      },
      {
        query: '(max-width: 1199px)',
        options: {
          slidesToScroll: 1,
          slideWidth: `50%`,
          draggable: true,
          gap: 20
        }
      },
      {
        query: '(max-width: 767px)',
        options: {
          slideWidth: `90%`,
          gap: 10
        }
      }
    ])
  }

  // Used on text template and single post templates

  if (document.querySelector('.embla__gallery')) {
    const wrap = document.querySelector('.embla__gallery').parentElement
    const options = {
      draggable: true,
      containScroll: 'trimSnaps',
      slidesToScroll: 3,
      arrowsDisplay: true,
      dotsDisplay: false,
      wrapSlides: false,
      slideWidth: `${100 / 3}%`,
      gap: 40,
      align: 'start'
    }
    new EmblaSliders(wrap, [
      {
        query: '(min-width: 1200px)',
        options: { ...options }
      },
      {
        query: '(max-width: 1199px)',
        options: { gap: 20 }
      },
      {
        query: '(max-width: 991px)',
        options: {
          slidesToScroll: 2,
          slideWidth: '50%'
        }
      },
      {
        query: '(max-width: 767px)',
        options: {
          slidesToScroll: 1,
          slideWidth: '70%'
        }
      },
      {
        query: '(max-width: 420px)',
        options: {
          slideWidth: '90%'
        }
      }
    ])
  }

  if (document.querySelector('.embla__team-grid')) {
    const wrap = document.querySelector('.embla__team-grid').parentElement
    const options = {
      containScroll: 'trimSnaps',
      slidesToScroll: 3,
      arrowsDisplay: false,
      dotsDisplay: false,
      wrapSlides: true,
      draggable: false,
      slideWidth: `50%`,
      gap: 40,
      align: 'start'
    }
    new EmblaSliders(wrap, [
      {
        query: '(min-width: 1200px)',
        options: { ...options }
      },
      {
        query: '(max-width: 1199px)',
        options: {
          slideWidth: `80%`,
          arrowsDisplay: true,
          wrapSlides: false,
          draggable: true,
          dragFree: true,
          slidesToScroll: 1
        }
      },
      {
        query: '(max-width: 767px)',
        options: {
          gap: 30,
          slideWidth: '90%'
        }
      },
      {
        query: '(max-width: 420px)',
        options: {
          gap: 20
        }
      }
    ])
  }

  // Used on the plastic problem page

  if (document.querySelector('.embla--lists-slider')) {
    ;[...document.querySelectorAll('.embla--lists-slider')].forEach($slider => {
      const wrap = $slider.parentElement
      const options = {
        draggable: true,
        dragFree: true,
        containScroll: 'trimSnaps',
        slidesToScroll: 1,
        arrowsDisplay: true,
        dotsDisplay: false,
        wrapSlides: false,
        slideWidth: `${100 / 3}%`
      }
      new EmblaSliders(wrap, [
        {
          query: '(min-width: 1585px)',
          options: { ...options }
        },
        {
          query: '(max-width: 1584px)',
          options: { ...options }
        },
        {
          query: '(max-width: 767px)',
          options: {
            slideWidth: '40%'
          }
        },
        {
          query: '(max-width: 600px)',
          options: {
            slideWidth: '48%'
          }
        },
        {
          query: '(max-width: 500px)',
          options: {
            slideWidth: '80%'
          }
        },
        {
          query: '(max-width: 350px)',
          options: {
            slideWidth: '84%'
          }
        }
      ])
    })
  }

  // Used on the about us page

  // ['(min-width: 1585px)', 1, 100 / 4, 'none', true, 'nowrap'],
  // 0: query, 1: slidesToScroll, 2: slidesWidth, 3: dotsDisplay, 4: arrowsDisplay, 5: containerFlex (wrap no wrap), 6: slidesGap (nema)

  if (document.querySelector('.embla--inner__bg-img-content')) {
    ;[...document.querySelectorAll('.embla--inner__bg-img-content')].forEach(
      $slider => {
        const wrap = $slider.parentElement
        const options = {
          containScroll: 'trimSnaps',
          slidesToScroll: 1,
          arrowsDisplay: true,
          dotsDisplay: false,
          wrapSlides: false,
          slideWidth: `${100 / 4}%`,
          gap: 2,
          align: 'start'
        }
        new EmblaSliders(wrap, [
          {
            query: '(min-width: 1585px)',
            options: { ...options }
          },
          {
            query: '(max-width: 1584px)',
            options: {
              slideWidth: `${100 / 4}%`
            }
          },
          {
            query: '(max-width: 1200px)',
            options: {
              slideWidth: `${100 / 3}%`
            }
          },
          {
            query: '(max-width: 767px)',
            options: {
              slideWidth: `42%`
            }
          },
          {
            query: '(max-width: 530px)',
            options: {
              slideWidth: `50%`
            }
          },
          {
            query: '(max-width: 460px)',
            options: {
              slideWidth: `90%`
            }
          }
        ])
      }
    )
  }

  // Used on About Us page
  if (document.querySelector('.embla--team-intro__inner__cards')) {
    ;[...document.querySelectorAll('.embla--team-intro__inner__cards')].forEach(
      $slider => {
        const wrap = $slider.parentElement
        const options = {
          draggable: true,
          dragFree: true,
          containScroll: 'trimSnaps',
          slidesToScroll: 1,
          slideWidth: `${100 / 3}%`,
          arrowsDisplay: true,
          dotsDisplay: false,
          wrapSlides: false,
          gap: 40
        }
        new EmblaSliders(wrap, [
          {
            query: '(min-width: 1585px)',
            options: { ...options }
          },
          {
            query: '(max-width: 1584px)',
            options: {
              gap: 40
            }
          },
          {
            query: '(max-width: 1200px)',
            options: {
              gap: 30
            }
          },
          {
            query: '(max-width: 900px)',
            options: {
              gap: 20
            }
          },
          {
            query: '(max-width: 767px)',
            options: {
              slideWidth: `42%`
            }
          },
          {
            query: '(max-width: 530px)',
            options: {
              slideWidth: `50%`
            }
          },
          {
            query: '(max-width: 460px)',
            options: {
              slideWidth: `90%`
            }
          }
        ])
      }
    )
  }

  // Used on the Plastic Problem page
  if (document.querySelector('.embla--title-txt-icons-grid')) {
    ;[...document.querySelectorAll('.embla--title-txt-icons-grid')].forEach(
      $slider => {
        const wrap = $slider.parentElement
        const options = {
          draggable: false,
          containScroll: 'trimSnaps',
          slidesToScroll: 1,
          arrowsDisplay: false,
          dotsDisplay: false,
          wrapSlides: true,
          slideWidth: `50%`,
          gap: 40,
          align: 'start'
        }
        new EmblaSliders(wrap, [
          {
            query: '(min-width: 1585px)',
            options: { ...options }
          },
          {
            query: '(max-width: 1584px)',
            options: { ...options }
          },
          {
            query: '(max-width: 767px)',
            options: {
              slideWidth: '60%',
              gap: 30,
              wrapSlides: false,
              draggable: true,
              arrowsDisplay: true
            }
          },
          {
            query: '(max-width: 600px)',
            options: {
              slideWidth: '80%',
              gap: 20
            }
          },
          {
            query: '(max-width: 450px)',
            options: { slideWidth: '94%' }
          }
        ])
      }
    )
  }
}
